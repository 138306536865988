import useFetchData from '../library/useFetchData'
import ReactMarkdown from 'react-markdown';
import remarkSuperscript from 'remark-supersub';

import {  } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
// faFunction, faPi
// <FontAwesomeIcon icon={faXmark} />
// <h4>Ω = ϕ<sup>-1</sup>(π)</h4>
//

interface Skill {
  skill_description: string;
  skill_type: string;
}

type CategoryComponentProps = {
  interests: Skill[];
};

function filterSkillsByType(skills: Skill[], type: string): Skill[] {
  return skills.filter(skill => skill.skill_type === type);
}

//<h4><ReactMarkdown remarkPlugins={[remarkSuperscript]}>{symbol}</ReactMarkdown></h4>

const InterestCategory: React.FC<CategoryComponentProps> = ({ interests }) => {
return (
    <>
       <h5>{interests[0].skill_type}</h5>
       <ul>
           {interests.map((i) => (<li>- {i.skill_description} &nbsp;</li>))}
       </ul>
    </>
    );
}


function Interests() {
  const [data, loading] = useFetchData<Skill[][]>('cv/skills');
  if (loading || !data) {
    return <div>Fetching...</div>;
  }
  if (!data) {
    return <div>Hm...</div>;
  }
  else {
    return(
    <div id="prices" className="row elem-border">
      <div className="col-lg-2 prices-left">
        <h3>Theme</h3>
      </div>
      <div className="col-lg-10">
        <div className="row prices-right">
          <div className="col-sm-4 col-12">
            <div className="prices-wrap">
              <h4>Ω</h4>
              <p>Economic Theory</p>
              <span>&larr;</span>
            </div>
            <div className="prices-details">
              <InterestCategory interests={ filterSkillsByType(data.flat(), "Economic Theory") } />
              <span>&larr;</span>
            </div>
          </div>
          <div className="col-sm-4 col-12">
            <div className="prices-wrap">
              <h4>ϕ<sup>-1</sup></h4>
              <p>Econometrics</p>
              <span>&larr;</span>
            </div>
            <div className="prices-details">
              <InterestCategory interests={ filterSkillsByType(data.flat(), "Econometrics") } />
              <span>&larr;</span>
            </div>
          </div>
          <div className="col-sm-4 col-12">
            <div className="prices-wrap reveal-left">
              <h4>π</h4>
              <p>Data</p>
              <span>&rarr;</span>
            </div>
            <div className="prices-details">
              <InterestCategory interests={ filterSkillsByType(data.flat(), "Data") } />
              <span>&larr;</span>
            </div>
          </div>
        </div>
      </div>
    </div>
   );
  }
}

export default Interests;
