import useFetchData from '../library/useFetchData'
import dateString from '../library/dateString'


interface Education {
  educ_start_date: Date;
  educ_end_date: Date;
  educ_name: string;
  educ_description: string;
  educ_link?: string; 
}

function Education() {
  const [data, loading] = useFetchData<Education[]>('cv/education');
  if (loading || !data) {
    return <div>Fetching...</div>;
  }
  if (!data) {
    return <div>Hm...</div>;
  }
  else {
    return(
      <>
        <h4>Education</h4>
        {data.map((e) => (
          <>
            <h5>{dateString(e.educ_start_date, e.educ_end_date)}: <a href={e.educ_link} target="_blank">{e.educ_name}</a></h5><p>{e.educ_description}</p>
          </>
       ))} 
      </>
  )
  }
}

export default Education;
