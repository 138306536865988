function Footer() {
  return(
  <div className="row elem-border">
      <div className="col-12 text-right">
        <p><a href="https://www.hubner.info">&copy; Stefan Hubner</a></p>
      </div>
    </div>
  );
}

export default Footer;
