import useFetchData from '../library/useFetchData'

interface InfoItem {
  key: string;
  value: string;
}

function Tagline() {
  const [data, loading] = useFetchData<InfoItem[]>('cv/info');
  if (loading || !data) {
    return <div>Fetching...</div>;
  }
  else {
    return(
    <div id="hero" className="row elem-border">
      <div className="col-xl-8 col-lg-7 col-md-5">
        <h3><span>Assistant Professor</span></h3>
        <h4><span>University of Bristol</span></h4>
      </div>
      <div className="col-xl-4 col-lg-5 col-md-7 text-left">
        <p>
          {data.map((par) => (
          <>
            <h5>{par.key}:</h5> {par.value} <br />
          </>
       ))} 
        </p>
      </div>
    </div>
  );
  }
}

export default Tagline;
