function Preloader() {
  return(
  <div className="preloader preloader-lg">
    <div>
      <p className="loading-text">
        <span>H</span>
        <span>u</span>
        <span>b</span>
        <span>n</span>
        <span>e</span>
        <span>r</span>
        <span>.</span>
        <span>i</span>
        <span>n</span>
        <span>f</span>
        <span>o</span>
      </p>
      <p>is loading</p>
    </div>
  </div>
  );
}

export default Preloader;
