import React, { ReactNode, useState } from 'react';

import "./Tooltip.css"

interface TooltipProps {
  text: string;        // Text to be displayed in the tooltip
  children: ReactNode; // The child elements over which the tooltip will appear
}

export const Tooltip: React.FC<TooltipProps> = ({ text, children }) => {
  const [show, setShow] = useState(false);

  return (
    <div 
      className="tooltip-wrapper" 
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      {children}
      {show && <div className="tooltip-content">{text}</div>}
    </div>
  );
};

export default Tooltip;

