import React, { createContext, useState, ReactNode, useContext } from 'react';

interface ModalContextType {
  content: ReactNode;
  setContent: (content: ReactNode) => void;
}

const defaultState = {
  content: null,
  setContent: () => {},
};

const ModalContext = createContext<ModalContextType>(defaultState);

export const useModalContext = () => useContext(ModalContext);

interface ModalProviderProps {
  children: ReactNode;
}

export const ModalProvider: React.FC<ModalProviderProps> = ({ children }) => {
  const [content, setContent] = useState<ReactNode>(null);

  return (
    <ModalContext.Provider value={{ content, setContent }}>
      {children}
    </ModalContext.Provider>
  );
};

export default ModalProvider;

