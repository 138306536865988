import React, { FunctionComponent, ReactNode } from 'react';
import { useModalContext } from './ModalContext';

const GenericModal: React.FC = () => {
  const modalClassName = `modal fade generic-modal`;
  const { content } = useModalContext();
  return(
    <div className={modalClassName} tabIndex={-1} role="dialog">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Information</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
            <div className="modal-body">
                <p style={{ textAlign: "justify" }} >{content}</p>
            </div>
            <div className="modal-footer text-center">
              <p></p>
            </div>
          </div>
      </div>
    </div>
  );
}

export default GenericModal;
