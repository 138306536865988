import useFetchData from '../library/useFetchData'
import dateString from '../library/dateString'

interface Position {
  pos_name: string;
  pos_link: string;
  pos_desc: string;
  start_date: Date;
  end_date: Date;
}

function Positions() {
  const [data, loading] = useFetchData<Position[]>('cv/positions');
  if (loading || !data) {
    return <div>Fetching...</div>;
  }
  else {
    return(
      <>
        <h4>Positions</h4>
        {data.map((pos) => (
          <>
            <h5>{dateString(pos.start_date, pos.end_date)}: <a href={pos.pos_link} target="_blank">{pos.pos_name}</a></h5><p>{pos.pos_desc}</p>
          </>
       ))} 
      </>
  )
  }
}

export default Positions;
