
function Nav() {
  return(
    <nav className="container-fluid">
    <div className="row elem-border">
      <div className="col-sm-10 anchor brand">
        <h1 className="stretch-text"><a href="/">
          <span className="stretch_it">Stefan Hubner</span>
        </a></h1>
      </div>
      <div className="col-sm-2 navigation">
        <ul className="navlist">
          <li className="anchor"><a href="#features">CV</a></li>
          <li className="anchor"><a href="#products">Research</a></li>
          <li className="anchor"><a href="#prices">Theme</a></li>
          <li className="anchor"><a href="#services">Teaching</a></li>
          <li className="anchor"><a href="#team">Genealogy</a></li>
          <li className="anchor"><a href="#contact">Contact</a></li>
        </ul>
      </div>
    </div>
  </nav>
  );
}

export default Nav;
