import { useState, useEffect } from 'react';
import React, { ReactNode } from 'react';

const useFetchData = <T,>(initialUrl: string): [T | null, boolean] => {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = process.env.REACT_APP_API_URL + initialUrl;
        const response = await fetch(url);
        //console.log('Debug response:', response);
        const jsonData: T = await response.json();
        //console.log('Debug data:', jsonData);
        setData(jsonData);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [initialUrl]);

  return [data, loading];
};

export default useFetchData;

