import useFetchData from '../library/useFetchData'

type Genealogy = {
  gen_location: string;
  gen_name: string;
  gen_year: number;
  thesis: string;
  imgurl: string;
};

function Team() {
  return(
    <>
      <div id="team" className="row elem-border">
        <div className="col-lg-3 col-md-12 team-left">
          <h3>Genealogy</h3>
        </div>
        <div className="team-carousel-wrap col-lg-9 col-md-12">
          <div className="team-carousel owl-carousel owl-theme">
            <TeamInner />
          </div>
          <div className="team-carousel-nav">
          </div>
        </div>
      </div>
    </>
  )
}


function TeamInner() {
  const [data, loading] = useFetchData<Genealogy[]>('genealogy');
  if (loading || !data) {
    return <div>Fetching...</div>;
  }
  else {
    return(
        <>
          {data.map((gen) => (
            <div className="team-member">
              <div className="team-wrap">
                <p><span>{gen.gen_location}, {gen.gen_year}</span></p>
                <div>
                  <img src={`img/gen/${gen.imgurl}`} alt={gen.gen_name} /> 
                </div>
                <span className="team-member-name">
                  <a className="btl-anchor" href="#">{gen.gen_name}</a>
                </span>
              </div>
            </div>
           ))} 
        </>
    )
  }
}

export default Team;
