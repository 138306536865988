import useFetchData from '../library/useFetchData'
import dateString from '../library/dateString'

import Positions from './Positions'
import Education from './Education'
import Paragraph from './Paragraph'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperclip, IconDefinition } from '@fortawesome/free-solid-svg-icons'

interface Conference {
  conf_location: string | null;
  conf_name: string;
  conf_year: number;
};

interface Service {
    service_desc: string;
    service_type: string;
}

interface Job {
  job_date_from: Date;
  job_date_to: Date;
  job_description: string;
  job_link: string;
  job_name: string;
}

interface Funding {
  funding_date_from: Date;
  funding_date_to: Date;
  funding_name: string;
}

export function Conferences() {
  const [data, loading] = useFetchData<Conference[][]>('cv/conferences');
  if (loading || !data) {
    return <div>Fetching...</div>;
  }
  if (!data) {
    return <div>Hm...</div>;
  }
  else {
  return (
   <>
     <div className="modal-body">
          {data.map((yearlyConferences, index) => (
            <>
              <h6>{yearlyConferences[0].conf_year}</h6>
              <ul>
                { yearlyConferences.map((conference, idx) => (
                <li>
                  {conference.conf_name}
                  {conference.conf_location && `, ${conference.conf_location}`}
                </li>
              )) }
              </ul>
              <br />
            </>
          ))}
     </div>
     <div className="modal-footer text-center">
            <p></p>
     </div>
   </>
  );
  }
}

export function Services() {
  const [data, loading] = useFetchData<Service[][]>('cv/services');
  if (loading || !data) {
    return <div>Fetching...</div>;
  }
  if (!data) {
    return <div>Hm...</div>;
  }
  else {
  return (
   <>
     <div className="modal-body">
            {data.map((serviceByType, index) => (
            <>
              <h6>{serviceByType[0].service_type}</h6>
              <ul>
                { serviceByType.map((service, idx) => (
                <li>
                  {service.service_desc}
                </li>
              )) }
              </ul>
              <br />
            </>
          ))}
          </div>
          <div className="modal-footer text-center">
            <p></p>
       </div>
     </>
  );
  }
}

function Jobs({jtype} : {jtype:string}) {
  const [data, loading] = useFetchData<Job[]>('cv/' + jtype);
  if (loading || !data) {
    return <div>Fetching...</div>;
  }
  if (!data) {
    return <div>Hm...</div>;
  }
  else {
    return(
      <>
        {data.map((j) => (
          <>
            <p>{dateString(j.job_date_from, j.job_date_to)}: <a href={j.job_link} target="_blank">{j.job_name}</a></p><p>{j.job_description}</p><br /> </>))} 
      </>
  )
  }
}


export function Industry() {
  return (
   <>
     <div className="modal-body">
       <h6>Full Time</h6>
        <br />
        <Jobs jtype = "professional" />
        <br />
       <h6>Projects, Parttime, Internships</h6>
        <br />
        <Jobs jtype = "internship" />
        <br />
     </div>
          <div className="modal-footer text-center">
            <p></p>
       </div>
     </>
  );
}

export function Scholarships() {
  const [data, loading] = useFetchData<Funding[]>('cv/funding');
  if (loading || !data) {
    return <div>Fetching...</div>;
  }
  if (!data) {
    return <div>Hm...</div>;
  }
  else {
    return(
     <>
     <div className="modal-body">
           <table>
                { data.map((f, idx) => (
                <tr>
                  <td style={{verticalAlign:"top", width:"20%"}}>{ dateString(f.funding_date_from, f.funding_date_to, false) } </td>
                  <td>{ f.funding_name }</td>
                </tr>
              )) }
           </table>
          </div>
          <div className="modal-footer text-center">
            <p></p>
       </div>
     </>
    );
  }
}


export function Features() {
  return(
    <div id="features" className="row">
      <div className="col-lg-6 col-md-12 features-left order-2 order-sm-2">
        <div className="row elem-border">
          <div className="col-xl-12 col-lg-12 col-md-6">
            <div className="row">
              <div className="col-lg-6">
                <h4>Conferences</h4>
                <Paragraph ptype="HeadConferences" />
              </div>
              <div className="col-lg-6 text-right">
                <div className="btn-style-main">
                    <a href="#" data-toggle="modal" data-target=".conferences-modal">
                    <span>Read more</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-12 col-lg-12 col-md-6">
            <div className="row">
              <div className="col-lg-6">
                <h4>Professional Services</h4>
                <Paragraph ptype="HeadServices" />
              </div>
              <div className="col-lg-6 text-right">
                <div className="btn-style-main">
                    <a href="#" data-toggle="modal" data-target=".services-modal">
                    <span>Read more</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-12 col-lg-12 col-md-6">
            <div className="row">
              <div className="col-lg-6">
                <h4>Industry Jobs</h4>
                <Paragraph ptype="HeadIndustry" />
              </div>
              <div className="col-lg-6 text-right">
                <div className="btn-style-main">
                    <a href="#" data-toggle="modal" data-target=".industry-modal">
                    <span>Read more</span>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-12 col-lg-12 col-md-6">
            <div className="row">
              <div className="col-lg-6">
                <h4>Scholarships</h4>
                <Paragraph ptype="HeadScholarships" />
              </div>
              <div className="col-lg-6 text-right">
                <div className="btn-style-main">
                    <a href="#" data-toggle="modal" data-target=".scholarships-modal">
                    <span>Read more</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-6 col-md-12 order-1 order-lg-2 order-sm-1">

        <div className="row elem-border features-title">
          <div className="col-12">
            <h3>
               Curriculum Vitae
            </h3>
          </div>
        </div>

        <div className="row elem-border features-right">
          <div className="col-lg-6 col-md-6">
            <div className="row">
              <div className="col-12">
                <Positions />
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6">
            <div className="row">
              <div className="col-12">
                <Education />
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}
