import Helmet from 'react-helmet';

import ModalProvider from './components/ModalContext';
import Modal from './components/Modal';
import GenericModal from './components/GenericModal';
import Preloader from './components/Preloader';
import Nav from './components/Nav';
import Tagline from './components/Tagline';
import { Features, Conferences, Services, Industry, Scholarships } from './components/Features';
import Products from './components/Products';
import Interests from './components/Interests';
import { Teaching, TeachingAt } from './components/Teaching';
import Team from './components/Team';
import Contact from './components/Contact';
import Footer from './components/Footer';

function App() {
  return (
    <>
      <ModalProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
          <meta http-equiv="x-ua-compatible" content="ie=edge" />
        </Helmet>
        <Preloader />
        <Modal ChildComponent={Services} title="Services" />
        <Modal ChildComponent={Conferences} title="Conferences" />
        <Modal ChildComponent={Industry} title="Industry" />
        <Modal ChildComponent={Scholarships} title="Scholarships" />
        <Modal ChildComponent={TeachingAt} title="Bristol" />
        <Modal ChildComponent={TeachingAt} title="Oxford" />
        <Modal ChildComponent={TeachingAt} title="Tilburg" />
        <Modal ChildComponent={TeachingAt} title="External" />
        <GenericModal />
        <Nav />
        <div className="container-fluid">
            <Tagline />
            <Features />
            <Products />
            <Interests />
            <Teaching />
            <Team />
            <Contact />
        <Footer />
        </div>
      </ModalProvider>
    </>
  );
}

export default App;
