import useFetchData from '../library/useFetchData'
import linkRenderer from '../library/linkRenderer'
import ReactMarkdown from 'react-markdown';

interface Par {
    par_date: Date;
    par_text: string;
    par_title: string;
}

type ParagraphProperties = {
    ptype: string;
}

const Paragraph: React.FC<ParagraphProperties> = ({ ptype }) => {
  const [data, loading] = useFetchData<Par[]>('paragraph/' + ptype);
  if (loading || !data) {
    return <div>Fetching...</div>;
  }
  if (!data) {
    return <div>Hm...</div>;
  }
  else {
  return (
    <p><ReactMarkdown components={{ a: linkRenderer }}>{ data.length > 0 ? data[0].par_text : "" }</ReactMarkdown></p>
  );
  }
}

export default Paragraph;
