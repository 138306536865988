import React, { FunctionComponent, ReactNode } from 'react';

type ModalComponentProps = {
  ChildComponent: FunctionComponent<{context: string}>;
  title: string;
};

const Modal: React.FC<ModalComponentProps> = ({ ChildComponent, title }) => {
  const modalClassName = `modal fade ${title}-modal`.trim().toLowerCase();
  return(
    <div className={modalClassName} tabIndex={-1} role="dialog">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{title}</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
             <ChildComponent context={title} />
          </div>
      </div>
    </div>
  );
}

export default Modal;
