
function dateString(start : Date, end : Date, since : boolean = true) {
  if (end == null && start == null)
   return ("*");
  else if (end == null && since)
    return ("since " + new Date(start).getFullYear());
  else if ((end == null && !since) || (new Date(start).getFullYear() == new Date(end).getFullYear()) )
    return ("" + new Date(start).getFullYear());
  else 
    return ((new Date(start)).getFullYear() + " - " + (new Date(end)).getFullYear());
}

export default dateString;
