import useFetchData from '../library/useFetchData'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperclip, faGear, faBars, faFilePdf, faRocket, faCode, IconDefinition } from '@fortawesome/free-solid-svg-icons'

import Tooltip from './Tooltip';
import GenericModal from './GenericModal';
import { useModalContext } from './ModalContext';

interface IconDictionary {
  [key: string]: IconDefinition;
}

const iconDict: IconDictionary = {
  "icon fa-file-pdf": faPaperclip, // faFilePdf or faPaperclip
  "icon fa-rocket": faGear, // faRocket or faGear
  "icon fa-code": faCode
};

interface Coauthor {
  coauthor_name: string;
}

interface Resource {
  tooltip: string;
  resource_link: string;
  resource_class: string;
}

interface Project {
  abstract: string;
  project_doi: string | null;
  project_title: string;
  project_year: number;
  reference: string | null;
}

interface StatusInfo {
  status_text: string;
}

// As coauthors and resources are arrays, their types remain the same
type Coauthors = Coauthor[];
type Resources = Resource[];

// Redefine ProjectInfoNested as a tuple
type ProjectInfoNested = [StatusInfo, Project, Coauthors, Resources];

function stripSpecial(title : string) {
  return(title.replace(/[^a-zA-Z0-9]/g, ''));
}
function Project(pi: ProjectInfoNested[], idx : number) {
   const imgsrc : string = stripSpecial(pi[idx][1].project_title)
   return(<div className="col-6">
            <div className="product-wrap" data-product-id={`${idx+1}`}>
              <img src={`img/projects/${imgsrc}.png`} 
                   alt={pi[idx][1].project_title} />
            </div>
          </div>)
}

function makeLink(link : string) {
  return(link.startsWith("/dl/") ? process.env.REACT_APP_API_URL + link.substring(1) : link);
}

function ProjectDetails(pi: ProjectInfoNested, idx : number) {
   const imgsrc : string = stripSpecial(pi[1].project_title)
   const { setContent } = useModalContext();

   const handleClick = (abstract: string) => (e: React.MouseEvent<HTMLAnchorElement>) => {
     e.preventDefault();
     setContent(abstract);
   };

   return(
           <div id={`product-${idx+1}`} 
             className="product-carousel-item" 
             style={{backgroundImage: `url(./img/projects/${imgsrc}.png)`}} >
                  <div className="product-description">
                      <h5>
                        {pi[1].project_doi ? <a href={pi[1].project_doi} target="_blank">{ pi[1].project_title}</a> : pi[1].project_title}
                      </h5>
                      <p>
                        <>
                        { pi[1].reference &&  pi[1].reference }
                        <br />
                        { pi[2].length > 0 && "with " }
                        { pi[2].map(coauthor => coauthor.coauthor_name).join(', ') }
                        <br />
                        <a href="#" data-toggle="modal" data-target=".generic-modal"
                           onClick={ handleClick(pi[1].abstract) }>
                             <FontAwesomeIcon icon={faBars} />
                        </a>
                        &nbsp; &nbsp;
                        {
                           pi[3].map((r : Resource) => (
                            <Tooltip text={r.tooltip} >
                               <a href={makeLink(r.resource_link)} target="_blank">
                                  {<FontAwesomeIcon icon={iconDict[r.resource_class]} />}
                               </a> &nbsp;
                            </Tooltip>
                           ))
                        }
                      </>
                      </p>
                      <a href="#" className="product-link">
                          &rarr;
                      </a>
                  </div>
            </div>
            )
}
function ProjectsTag({side}: {side: string}) {
  const [data, loading] = useFetchData<ProjectInfoNested[][]>('pubsbytype');
  if (loading || !data) {
    return <div>Fetching...</div>;
  }
  if (!data) {
    return <div>Hm...</div>;
  }
  else {
    const indices = [0, 2, 1];
    const odata = indices.map(index => data[index]);
    const ps : ProjectInfoNested[] = odata.flatMap(level1 => level1);
    const offset = side === "right" ? 4 : 0;
  return(
   <>
        <div className="row products-left">
          { Project(ps, 0 + offset) }
          { Project(ps, 1 + offset) }
        </div>
        <div className="row products-left">
          { Project(ps, 2 + offset) }
          { Project(ps, 3 + offset) }
        </div>
    </>
   );
  }
}

function ProjectDetailsTag() {
  const [data, loading] = useFetchData<ProjectInfoNested[][]>('pubsbytype');
  if (loading || !data) {
    return <div>Fetching...</div>;
  }
  if (!data) {
    return <div>Hm...</div>;
  }
  else {
    const indices = [0, 2, 1];
    const odata = indices.map(index => data[index]);
    const ps : ProjectInfoNested[] = odata.flatMap(level1 => level1);
  return(
   <>
      { ps.map((p, idx) => (
                 ProjectDetails(p, idx)
                 ))}
   </>
  );
  }
}


// the frame must be there for the javascript carousel init
// this is ugly, but the only way this works
function Products() {
  return(
    <div id="products" className="row elem-border">

      <div className="col-lg-4 col-md-12 order-2 order-lg-1 order-md-2 order-sm-2 products-left-outer">
        <ProjectsTag side="left" />
      </div>

      <div className="col-lg-4 col-md-12 order-1 order-lg-2 order-md-1 order-sm-1 text-center products-center">

          <div className="products-title-mobile">
              <h3>Research</h3>
          </div>

          <div className="products-carousel owl-carousel owl-theme">

              <div className="products-title-carousel">
                  <h3>Research</h3>
              </div>

            <ProjectDetailsTag />

          </div>
      </div>

      <div className="col-lg-4 col-md-12 order-3 products-right-outer">
         <ProjectsTag side="right" />
      </div>

     </div>
    )
}

export default Products;
