import useFetchData from '../library/useFetchData'
import dateString from '../library/dateString'
import linkRenderer from '../library/linkRenderer'
import ReactMarkdown from 'react-markdown'; 

import './Teaching.css'

interface Institution {
    institution_name: string;
}

interface Course {
    course_end_date: Date;
    course_start_date: Date;
    course_title: string;
}

interface Content {
    content_desc: string;
    content_head: string;
}

function groupByContentHead(contents: Content[]): Content[][] {
    interface GroupedContents {
        [key: string]: Content[];
    }

    const grouped: GroupedContents = contents.reduce<GroupedContents>((accumulator, content) => {
        const key = content.content_head;
        if (!accumulator[key]) {
            accumulator[key] = [];
        }
        accumulator[key].push(content);
        return accumulator;
    }, {});

    return Object.values(grouped);
}

type Contents = Content[];
type CourseInfoNested = [Institution, Course, Contents];

export function TeachingAt({context} : {context:string}) {
  const [data, loading] = useFetchData<CourseInfoNested[]>('teachingat/'+context);
  if (loading || !data) {
    return <div>Fetching...</div>;
  }
  if (!data) {
    return <div>Hm...</div>;
  }
  else {
  return(
   <>
     <div className="modal-body">
       <h6>{data[0][0].institution_name}</h6>
           <table>
              { data.map((ci, idx) => (
                <>
                <tr>
                  <td style={{verticalAlign:"top", width:"20%"}}>{ dateString(ci[1].course_start_date, ci[1].course_end_date, true) } </td>
                  <td>{ ci[1].course_title }</td>
                </tr>
                <tr>
                 <td></td>
                 <td>
                {groupByContentHead(ci[2]).map((cs) => (
                  <div className="markdown-list">
                    <u>{cs[0].content_head}</u>
                    <ul> 
                    {cs.map((c) => (
                    <li>
                      <ReactMarkdown components={{ a: linkRenderer }}>
                        {c.content_desc}
                      </ReactMarkdown>
                    </li>
                    )) }
                    </ul>
                    <br />
                  </div>
                ))}
                 </td>
                </tr>
                </>)) 
              }
           </table>
     </div>
     <div className="modal-footer text-center">
        <p>&nbsp;</p>
     </div>
     </>
  );
  }
}

export function Teaching() {
  return(
    <div id="services" className="row">
      <div className="col-lg-4 col-md-12 order-2 order-lg-1 order-md-2 order-sm-2">
        <div className="row elem-border services-left">
          <div className="col-lg-12 col-sm-6 col-6">
            <h4>Bristol</h4>
            <a href="#" data-toggle="modal" data-target=".bristol-modal">Read more</a>
          </div>
          <div className="col-lg-12 col-sm-6 col-6">
              <h4>Oxford</h4>
            <a href="#" data-toggle="modal" data-target=".oxford-modal">Read more</a>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-12 order-1 order-lg-2 order-md-1 order-sm-1 text-center services-center">
        <h3>Teaching</h3>
      </div>
      <div className="col-lg-4 col-md-12 order-3">
        <div className="row elem-border services-right">
          <div className="col-lg-12 col-sm-6 col-6">
            <h4>Tilburg</h4>
            <a href="#" data-toggle="modal" data-target=".tilburg-modal">Read more</a>
          </div>
          <div className="col-lg-12 col-sm-6 col-6">
            <h4>External</h4>
            <a href="#" data-toggle="modal" data-target=".external-modal">Read more</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Teaching;
