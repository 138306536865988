function Contact() {
  const handleClick = (url : string) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };
  return(
  <div id="contact" className="row elem-border">
      <div className="col-lg-2 col-md-12 order-2 order-lg-1 order-md-2 contact-left">
        <ul id="social">
          <li onClick={() => handleClick('https://github.com/StefanHubner/')}>GitHub</li>
          <li onClick={() => handleClick('https://bsky.app/profile/hubner.info')}>Bluesky</li>
          <li onClick={() => handleClick('https://www.linkedin.com/in/stefan-hubner-600a5545/')}>LinkedIn</li>
        </ul>
      </div>
      <div className="col-lg-10 col-md-12 order-1 order-lg-2 order-md-1 contact-right">
        <div className="row">
          <div className="col-md-3 col-sm-12 contact-title">
            <h3>Contact</h3>
          </div>
          <div className="col-md-9 col-sm-12">
            <form className="contact-form" method="post" action={process.env.REACT_APP_API_URL + "mail/"}>
              <span className="sending">Sending Your Message...</span>
              <span className="sent">Message received, I'll be in touch.</span>
              <div className="form-group">
                <label htmlFor="contact-name">Name</label>
                <input type="text" className="form-control" id="contact-name" name="name" placeholder="Name" required />
                <input type="email" className="form-control" id="contact-email" name="email" placeholder="Email" required />
                <input type="hidden" id="g-recaptcha-response" name="g-recaptcha-response" value="" />
                <label htmlFor="contact-email">Email</label>
              </div>
              <div className="form-group">
                <label htmlFor="message">Message</label>
                <textarea className="form-control" name="message" id="message" placeholder="Message"></textarea>
              </div>
              <div className="text-right">
                <button type="submit" className="btn btn-primary">Send</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
